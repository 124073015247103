<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="90%" no-header id="view_help" backdrop style="direction:ltr" right :title="pagename" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{ lang.training }}</span>
                    </div>
                    <div @click="hide" id="vohclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <iframe width="100%" style="height: calc(100vh - 100px);" src="https://samcotec.com/help/"></iframe>
                </div>
            </template>
            
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    
    data() {
        return {
            modal: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pagename: this.$parent.lang.add_new_account,
            jv_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            btnname: this.$parent.lang.add,
            jvnom: 1,
            id: 0,
            vouchers: [],
            newitem: {
                id: 0,
                classid: '',
                acount_name: '',
                credit: '',
                depit: '',
                notes: '',
            },
            showSearch: false,
            classes: [],
            notes: '',
            deleted: [],
            jvtype: 1
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        depitPlus: function(){
            let t = 0;
            for(let i=0;i<this.vouchers.length;i++){
                let v = this.vouchers[i].depit == '' ? 0 : this.vouchers[i].depit;
                t = parseFloat(t) + parseFloat(v);
            }
            return this.$RoundNum(t);
        },
        creditPlus: function(){
            let t = 0;
            for(let i=0;i<this.vouchers.length;i++){
                let v = this.vouchers[i].credit == '' ? 0 : this.vouchers[i].credit;
                t = parseFloat(t) + parseFloat(v);
            }
            return this.$RoundNum(t);
        },

    },
    created() {
        this.getclasses();
    },
    methods: {
        changeMe(){
            const myclasses = this.newitem.acount_name.split(" - ");
            this.newitem.classid = myclasses[0]
        },
        pressF2(field,e){
            var KeyID = (window.event) ? event.keyCode : e.keyCode;
            // console.log(KeyID);
            if(KeyID == 43){
                if(field == 'classid'){
                    this.showSearch = true;
                }
            }
            event.preventDefault()

        },
        checkcd(id){
            if(id == 'c'){
                if(this.newitem.depit != 0){
                    this.newitem.credit = 0;
                }
            }
            if(id == 'd'){
                if(this.newitem.credit != 0){
                    this.newitem.depit = 0;
                }
            }
        },
        removeme(item,index){
            this.deleted.push(item.id)
            this.vouchers.splice(index, 1);
        },
        addnew(){
            if
            (
                this.newitem.classid == '' || 
                this.newitem.acount_name == '' || 
                (this.newitem.credit == '' && 
                this.newitem.depit == '')  || 
                (this.newitem.credit == 0 && 
                this.newitem.depit == 0) 
            ){
                return false;
            }
            this.vouchers.push(this.newitem);
            this.newitem = {
                id: 0,
                classid: '',
                acount_name: '',
                credit: '',
                depit: '',
                notes: '',
            }
        },
        getName(){
            const post = new FormData();
            post.append("type","getClassname")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            post.append("data[classid]",this.newitem.classid);
            post.append("data[jv]",2);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(res.results.data.length == 0) {
                    this.newitem.classid = '';
                    return false;
                }
                this.newitem.acount_name = res.results.data.namear;
            })
        },
        getMain(){
            //
        },
        async getJV() {
            const post = new FormData();
            post.append("type","getJV");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            post.append("data[jvnom]",this.jvnom);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post);
            if(response && response.data){
                
                this.id = response.data.results.data.jv.id;
                this.jv_date = response.data.results.data.jv.jv_date;
                this.notes = response.data.results.data.jv.notes;
                const rows = response.data.results.data.rows;
                let jvRow = {};
                for(let i=0;i<rows.length;i++){
                    let classname = '';
                    for(let j=0;j<this.classes.length;j++){
                        if(this.classes[j].classid == rows[i].classid){
                            // TODO : check arabic/english
                            classname =  this.classes[j].namear;
                        }
                    }
                    console.log(rows[i])
                    jvRow = {
                        id: rows[i].id,
                        classid: rows[i].classid,
                        depit: rows[i].depit,
                        credit: rows[i].credit,
                        notes: rows[i].notes,
                        acount_name: classname
                    }
                    this.vouchers.push(jvRow)
                }
            }
        },
        getclasses() {
            const post = new FormData();
            post.append("type","getPostedCOA")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;    
                this.classes = res.results.data;
            });
        },
        addit() {
            if(this.vouchers.length == 0){
                this.$snotify.error('لا يمكن اضافة قيد  فارغ', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.depitPlus != this.creditPlus){
                this.$snotify.error('القيد غير متوازن', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }

            const post = new FormData();
            post.append("type","addEditJV");
            // console.log(this.$parent.lang.langname);
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname)
            post.append("data[type]",this.jvtype)
            
            for(let i=0;i<this.vouchers.length;i++){
                post.append('data[rows]['+i+'][id]', typeof this.vouchers[i].id !== 'undefined' ? this.vouchers[i].id : 0);
                post.append('data[rows]['+i+'][classid]',this.vouchers[i].classid);
                post.append('data[rows]['+i+'][acount_name]',this.vouchers[i].acount_name);
                post.append('data[rows]['+i+'][depit]',this.vouchers[i].depit);
                post.append('data[rows]['+i+'][credit]',this.vouchers[i].credit);
                post.append('data[rows]['+i+'][notes]',this.vouchers[i].notes);
            }
            post.append("data[id]",this.id);
            post.append("data[jvnom]",this.jvnom);
            post.append("data[jv_date]",this.jv_date);
            post.append("data[notes]",this.notes);
            for(let i=0;i<this.deleted.length;i++){
                post.append("data[deleted][]",this.deleted[i]);
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;   
                console.log(res);
                document.getElementById('vohclose').click();
                let message = "تمت اضافة القيد ";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close,  
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getExpensesCall();
            })
        }
    },
}
</script>